import React from 'react';
import * as Styled from './eBillingPopUpStyles';
import constants from '../../utils/utils';
import { graphql, useStaticQuery } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import isExternalUrl, { addTrailingSlash, isExternalUrlHref } from '../../utils';

const EBillingPopUp = ({ modalShow, handleModalShow }) => {
  const eBillingPopUpQuery = useStaticQuery(graphql`
    query eBillingPopUpDefault {
      allContentfulColumbiaFuel(
        filter: {
          identifier: { type: { eq: "ebillingPopUp" } }
          node_locale: { eq: "en-US" }
        }
      ) {
        edges {
          node {
            identifier {
              type
            }
            title
            slug
            sections {
              ... on ContentfulContent {
                id
                title
                type
                header
                contentDetails {
                  raw
                }
              }
              ... on ContentfulImageWithPath {
                title
                type
                image {
                  title
                  file {
                    url
                    details {
                      image {
                        width
                        height
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const propanePopUpData =
    eBillingPopUpQuery.allContentfulColumbiaFuel.edges[0].node.sections;

  const popUpContent = propanePopUpData.filter((section) => {
    return section.type === constants.COMMON.EBILLING_POP_UP.POP_UP_CONTENT;
  })[0];

  const backgroundImage = propanePopUpData.filter((section) => {
    return section.type === constants.COMMON.EBILLING_POP_UP.BACKGROUND_IMAGE;
  })[0];

  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ParaText>{children}</Styled.ParaText>
      ),
      [INLINES.HYPERLINK]: (node, children) => {
        return isExternalUrl(node.data.uri) ? (
          <Styled.LinkStyle href={isExternalUrlHref(node.data.uri)? node.data.uri : addTrailingSlash(process.env.GATSBY_DOMAIN_URL + node.data.uri)} target="_blank">
            {children}
          </Styled.LinkStyle>
        ) : (
          <Styled.LinkStyleInternal to={isExternalUrlHref(node?.data?.uri)
              ? node?.data?.uri
              : addTrailingSlash(process.env.GATSBY_DOMAIN_URL + node?.data?.uri)
          }
          target={isExternalUrl(node?.data?.uri) ? '_blank' : '_self'}>
            {children}
          </Styled.LinkStyleInternal>
        );
      },
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
  };

  return (
    <>
      <Styled.MainModal
        show={modalShow}
        onHide={() => handleModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Styled.CloseImage
          onClick={() => {
            handleModalShow(false);
          }}
        />
        <Styled.CustomerSelect $imageUrl={backgroundImage?.image?.file?.url}>
          {documentToReactComponents(
            JSON.parse(popUpContent?.contentDetails?.raw),
            optionsMainStyle
          )}
        </Styled.CustomerSelect>
      </Styled.MainModal>
    </>
  );
};
export default EBillingPopUp;
